import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import VueRouter from 'vue-router'

Vue.config.productionTip = false

Vue.use(VueRouter)

// Define your routes
const routes = [
  { path: '/', component: () => import('./components/LandingPage.vue') },
  { path: '/dashboard', component: () => import('./components/datatable.vue') }
]

const router = new VueRouter({
  routes
})


new Vue({
  vuetify,
  render: h => h(App),
  router
}).$mount('#app')
